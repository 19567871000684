<template>
  <div v-if="wrapperVisible || wrapperVisible2" class="global-modal" @click="clickMask">
    <div :class="['global-modal-container', innerClass]" :style="containerStyle" @click.stop>
      <img
        v-if="popupType === 'shareDone'"
        class="close-icon"
        src="@/assets/images/img_close_black.png"
        @click="hideCallRef"
      />
      <img
        v-else-if="preEntryXBtn"
        class="close-icon"
        src="@/assets/images/img_close_black.png"
        @click="goToPlayNft"
      />
      <img
        v-else-if="showClose"
        class="close-icon"
        src="@/assets/images/img_close_black.png"
        @click="hide"
      />
      <img
        v-else
        class="close-icon"
        src="@/assets/images/img_close_black.png"
        @click="refreshHide"
      />
      <div class="flex flex-col justify-center items-center">
        <template v-if="popupType === 'game_install'">
          <div class="flex flex-col justify-center items-center">
            <div class="h-5"></div>
            <div class="text-2xl text-center">
              NFT 를 활성화하기 위해서는<br />
              {{ nftList[store.state.nftId].name }}
              App 을<br />설치해야 합니다.
            </div>
            <div class="h-10"></div>
            <div class="flex items-center justify-center">
              <img :src="nftList[store.state.nftId].image" />
            </div>
            <div class="h-10"></div>
            <div class="text-xl text-center">
              연동되는 어플리케이션은 설치 후 동일한<br />구글 로그인을 사용해야 합니다.
            </div>
            <div class="h-10"></div>
            <div class="w-full flex justify-center items-center">
              <button
                class="wp-40 p-2 font-semibold text-2xl text-white game-btn"
                @click="resData(store.state.nftId.toString())"
              >
                설치하기
              </button>
            </div>
          </div>
        </template>
        <template v-if="popupType === 'game_off'">
          <div class="flex flex-col justify-center items-center">
            <div class="h-5"></div>
            <div class="text-2xl text-center font-medium">
              NFT 를 <span style="color: red">OFF</span> 하면<br />탄소절감 데이터가 적용<br />되지
              않습니다.
            </div>
            <div class="h-10"></div>
            <div class="w-full flex justify-center items-center">
              <button
                class="wp-60 p-2 font-semibold text-2xl text-white off-btn"
                @click="resData('OFF')"
              >
                OFF
              </button>
            </div>
          </div>
        </template>
        <template v-if="popupType === 'duplicate_nft_buy'">
          <div>
            <div class="mb-16 text-3xl font-bold text-black">
              탄소감축을 위한 <br />
              NFT는 반복 구매할 수 없습니다.
            </div>
          </div>

          <div>
            <div class="font-semibold text-xl text-black text-2xl">
              탄소감축 "제로퀘스트"를 <br />
              친구들에게 추천하면<br />
              <span class="text-esg-color font-bold">50ESG point</span> 를 드립니다
            </div>
          </div>

          <div class="mt-7 mb-4">
            <!-- 카카오 공유 -->
            <button type="button">
              <a id="kakao-link-btn" @click="shareKakaoAndroid">
                <img src="@/assets/images/kakao_logo.png" alt="카카오톡 공유하기" />
              </a>
              <div class="text-lg font-medium">Android</div>
            </button>
            <button type="button" class="ml-2">
              <a id="kakao-link-btn" @click="shareKakaoIOS">
                <img src="@/assets/images/kakao_logo.png" alt="카카오톡 공유하기" />
              </a>
              <div class="text-lg font-medium">Ios</div>
            </button>

            <!-- 텔레그램 공유 -->
            <button type="button" class="sns_btn" @click="shareTelegram">
              <img src="@/assets/images/telog.png" alt="텔레그램 공유하기" />
            </button>
          </div>
        </template>
        <template v-if="popupType === 'successMinting'">
          <div>
            <div class="mb-10 flex justify-center">
              <img class="wp-30" src="@/assets/images/icon_success.png" />
            </div>
            <div class="mb-10 text-3xl font-bold">NFT 민팅 성공!</div>
            <div class="mb-10 text-3xl text-red-700 font-bold">{{ title }}</div>
            <div>
              <button
                class="w-48 h-12 font-semibold text-white text-xl rounded close-btn"
                @click="refreshHide"
              >
                확인
              </button>
            </div>
          </div>
        </template>
        <template v-if="popupType === 'serviceChecking'">
          <div>
            <div class="mb-10 text-3xl font-bold">구입이 완료되었습니다.</div>
            <div>
              <button
                class="w-48 h-12 font-semibold text-white text-xl rounded close-btn"
                @click="hide"
              >
                확인
              </button>
            </div>
          </div>
        </template>
        <template v-if="popupType === 'VerificationCmpleted'">
          <div>
            <div class="mb-10 text-3xl font-bold">
              본인인증을 완료한뒤<br />
              출금 신청하기를 진행해주세요.
            </div>
            <div>
              <button
                class="w-48 h-12 font-semibold text-white text-xl rounded close-btn"
                @click="hide"
              >
                확인
              </button>
            </div>
          </div>
        </template>
        <template v-if="popupType === 'withDraw'">
          <div class="mb-10 text-3xl font-bold">정말 탈퇴하시겠어요?</div>
          <div>
            <div class="mb-10 text-2xl font-semibold">
              탈퇴시, 즉시 탈퇴가 완료되어 <br />
              쌓인 포인트와 NFT정보가 삭제되고<br />
              복구가 불가능합니다.
            </div>
            <div class="flex justify-between">
              <button
                class="w-44 h-12 font-semibold text-xl rounded close-btn bg-white text-green-800 border border-gray-300 border-solid"
                @click="hide"
              >
                취소
              </button>
              <button
                class="w-44 h-12 font-semibold text-white text-xl rounded close-btn bg-green-700"
                @click="withdrawBtn"
              >
                탈퇴하기
              </button>
            </div>
          </div>
        </template>
        <template v-if="popupType === 'successWithdraw'">
          <div>
            <div class="mb-4 text-2xl font-bold">회원탈퇴가 완료되었습니다.<br /></div>
            <div class="mb-10 text-xl font-semibold">
              아쉽지만 회원님과의 <br />
              다음 만남을 기대하겠습니다.<br />
            </div>
            <div>
              <button
                class="w-48 h-12 font-semibold text-white text-xl rounded close-btn"
                @click="refreshHide"
              >
                확인
              </button>
            </div>
          </div>
        </template>
        <template v-if="popupType === 'esgPointCharge'">
          <div>
            <div class="mb-10 text-2xl font-bold">
              ESG point 충전하기는 서비스 준비중입니다. <br />
              충전해서 사용하기 보다는 탄소감축을 통해서 <br />
              ESG point를 수집하세요
            </div>
            <div>
              <button
                class="w-48 h-12 font-semibold text-white text-xl rounded close-btn"
                @click="hide"
              >
                확인
              </button>
            </div>
          </div>
        </template>
        <template v-if="popupType === 'InsufficientPoints'">
          <div>
            <div class="mb-10 text-3xl font-bold">포인트가 부족합니다</div>
            <div>
              <button
                class="w-48 h-12 font-semibold text-white text-xl rounded close-btn"
                @click="hide"
              >
                확인
              </button>
            </div>
          </div>
        </template>

        <template v-if="popupType === 'PreparingForService'">
          <div>
            <div class="mb-10 text-3xl font-bold">서비스 준비중</div>
            <div>
              <button
                class="w-48 h-12 font-semibold text-white text-xl rounded close-btn"
                @click="hide"
              >
                확인
              </button>
            </div>
          </div>
        </template>
        <template v-if="popupType === 'pandaNFTSoldOut'">
          <div>
            <div class="mb-10 text-3xl font-bold">
              NFT 판매가 완료됐습니다. <br />
              <div class="text-2xl font-semibold mt-5">
                다음 스페셜 NFT를 기대해 주세요.<br />
                환경과 멸종 위기 동물에 <br />
                관심 가져주셔서 감사합니다.<br />
              </div>
            </div>
            <div>
              <button
                class="w-48 h-12 font-semibold text-white text-xl rounded close-btn"
                @click="hide"
              >
                확인
              </button>
            </div>
          </div>
        </template>
        <template v-if="popupType === 'mongoliaNFTSoldOut'">
          <div>
            <div class="mb-10 text-3xl font-bold">
              몽골나무 NFT 판매가 완료됐습니다. <br />
              <div class="text-2xl font-semibold mt-5">
                다음 몽골나무 NFT를 기대해 주세요.<br />
                관심 가져주셔서 감사합니다.<br />
              </div>
            </div>
            <div>
              <button
                class="w-48 h-12 font-semibold text-white text-xl rounded close-btn"
                @click="hide"
              >
                확인
              </button>
            </div>
          </div>
        </template>
        <template v-if="popupType === 'informationMessage'">
          <div>
            <div class="mb-10 text-3xl font-bold">
              {{ showTitle }}
            </div>
            <div>
              <button
                v-if="showClose"
                class="w-48 h-12 font-semibold text-white text-xl rounded close-btn"
                @click="hide"
              >
                확인
              </button>
              <button
                v-else
                class="w-48 h-12 font-semibold text-white text-xl rounded close-btn"
                @click="refreshHide"
              >
                확인
              </button>
            </div>
          </div>
        </template>
        <template v-if="popupType === 'tree_nft'">
          <div>
            <div>
              <img class="error-icon" src="@/assets/images/icon_error.png" />
            </div>
            <div class="mb-10 text-3xl font-bold">Coming soon.</div>
            <div>
              <button
                class="w-48 h-12 font-semibold text-white text-xl rounded close-btn"
                @click="hide"
              >
                확인
              </button>
            </div>
          </div>
        </template>

        <template v-if="popupType === 'successReferral'">
          <div>
            <div class="mb-10 flex justify-center">
              <img class="wp-30" src="@/assets/images/icon_success.png" />
            </div>
            <div class="mb-10 text-3xl font-bold">추천인 코드 입력 완료</div>
            <div class="mb-10 text-2xl font-bold">추천인에게 1000ESG point가 적립되었습니다.</div>
            <div>
              <button
                class="w-48 h-12 font-semibold text-white text-xl rounded close-btn"
                @click="hide"
              >
                확인
              </button>
            </div>
          </div>
        </template>
        <template v-if="popupType === 'shareDone'">
          <div>
            <div class="font-bold text-2xl pb-8">공유하기를 하였습니다.</div>
            <div>
              <button
                class="w-48 h-12 font-semibold text-white text-xl rounded close-btn"
                @click="hideCallRef"
              >
                Closed
              </button>
            </div>
          </div>
        </template>
        <template v-if="popupType === 'shareSuccess'">
          <div>
            <div class="mb-10 flex justify-center">
              <img class="wp-30" src="@/assets/images/icon_success.png" />
            </div>
            <div class="font-bold text-2xl pb-8">공유하기를 성공하였습니다.</div>
            <div>
              <button
                class="w-48 h-12 font-semibold text-white text-xl rounded close-btn"
                @click="refreshHide"
              >
                Closed
              </button>
            </div>
          </div>
        </template>
        <template v-if="popupType === 'pointConversion'">
          <div>
            <div class="mb-10 flex justify-center">
              <img class="wp-30" src="@/assets/images/icon_success.png" />
            </div>
            <div class="font-bold text-2xl pb-8">계단오르기 {{ pointValue }} 포인트 전환 완료</div>
            <div>
              <button
                class="w-48 h-12 font-semibold text-white text-xl rounded close-btn"
                @click="refreshHide"
              >
                Closed
              </button>
            </div>
          </div>
        </template>
        <template v-if="popupType === 'preEntryCompleted'">
          <div class="mt-10">
            <div class="text-2xl">
              <span class="font-semibold text-[#24d120]">입장신청 NFT 발급이 완료</span>
              <span class="font-semibold">되었습니다.</span>
            </div>
            <div class="py-3 text-xl">
              <span class="font-bold border-b-[1px] border-black">PlayNFT</span>
              <span class="text-gray-500">에서 신청결과를 확인하실 수 있습니다.</span>
            </div>
            <!-- <div class="bg-green-50 py-5 rounded-xl text-2xl my-2">
              <span>보상 :</span>
              <span class="px-1 font-bold text-[#24d120]">{{ title }}</span>
              <span>ESG point</span>
            </div> -->
            <div class="mt-8">
              <button
                class="w-48 h-12 font-semibold text-white text-xl rounded bg-[#0c5c26]"
                @click="goToPlayNft"
              >
                PlayNFT 바로가기
              </button>
            </div>
          </div>
        </template>
        <template v-if="popupType === 'service'">
          <div>
            <div class="font-bold text-2xl pb-8">{{ title }}</div>
            <div>
              <button
                class="w-48 h-12 font-semibold text-white text-xl rounded close-btn"
                @click="hide"
              >
                확인
              </button>
            </div>
          </div>
        </template>
        <template v-if="popupType === 'comming_soon_ai'">
          <div>
            <div>
              <img class="error-icon" src="@/assets/images/icon_error.png" />
            </div>
            <div class="h-10"></div>
            <div class="mb-10 text-3xl font-bold">서비스 점검 중</div>
            <div>
              <button
                class="w-48 h-12 font-semibold text-white text-xl rounded close-btn"
                @click="hide"
              >
                확인
              </button>
            </div>
          </div>
        </template>
        <!-- <template v-if="popupType === 'pleaseTag'">
					<div>
						<div class="font-bold text-2xl pb-8">nfc를 태그해주세요.</div>
						<div>
							<button
								class="w-48 h-12 font-semibold text-white text-xl rounded close-btn"
								@click="hide"
							>
								확인
							</button>
						</div>
					</div>
				</template> -->
        <template
          v-if="
            popupType !== 'game_install' &&
            popupType !== 'game_off' &&
            popupType !== 'duplicate_nft_buy' &&
            popupType !== 'successMinting' &&
            popupType !== 'serviceChecking' &&
            popupType !== 'InsufficientPoints' &&
            popupType !== 'VerificationCmpleted' &&
            popupType !== 'esgPointCharge' &&
            popupType !== 'PreparingForService' &&
            popupType !== 'informationMessage' &&
            popupType !== 'tree_nft' &&
            popupType !== 'SelectionIsNotComplete' &&
            popupType !== 'SelectionIsComplete' &&
            popupType !== 'SelectionIsCompleted' &&
            popupType !== 'VoteIsOver' &&
            popupType !== 'withDraw' &&
            popupType !== 'successWithdraw' &&
            popupType !== 'pandaNFTSoldOut' &&
            popupType !== 'successReferral' &&
            popupType !== 'wmuVoteIsOver' &&
            popupType !== 'shareDone' &&
            popupType !== 'shareSuccess' &&
            popupType !== 'pointConversion' &&
            popupType !== 'preEntryCompleted' &&
            popupType !== 'pleaseTag' &&
            popupType !== 'service' &&
            popupType !== 'comming_soon_ai'
          "
        >
          <div>
            <img class="error-icon" src="@/assets/images/icon_error.png" />
          </div>
          <div class="h-10"></div>
          <div class="h-10"></div>
          <div class="text-2xl text-center">{{ showTitle }}</div>
          <div class="h-10"></div>
          <div v-if="popupType !== 'message'" class="flex justify-center">
            <div>
              <button
                class="w-36 h-12 font-semibold text-white text-xl rounded close-btn"
                @click="resData('yes')"
              >
                YES
              </button>
            </div>
            <div class="w-20"></div>
            <div>
              <button
                class="w-36 h-12 font-semibold text-white text-xl rounded close-btn"
                @click="resData('no')"
              >
                NO
              </button>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import store from "@/store";
import http from "@/api/http";
import { computed, onMounted, ref, toRefs, watch, reactive, nextTick } from "vue";
import { useStore } from "vuex";

const vuexStore = useStore();
const referral = computed(() => vuexStore.getters["auth/getReferral"]);
const accessToken = store.getters["auth/getAccessToken"];
const props = defineProps({
  visible: {
    type: Boolean,
    default: () => false,
  },
  withdrawVisible: {
    type: Boolean,
    default: () => false,
  },
  innerStyle: {
    type: Object,
    default: () => ({}),
  },
  innerClass: {
    type: String,
    default: () => "",
  },
  popupType: {
    type: String,
    default: () => "Error",
  },
  title: {
    type: String,
    default: () => "",
  },
  showClose: {
    type: Boolean,
    default: () => true,
  },
  preEntryXBtn: {
    type: Boolean,
    default: () => false,
  },
  pointValue: {
    type: String,
    default: () => "",
  },
});

const emit = defineEmits([
  "afterShow",
  "afterHide",
  "afterLogin",
  "show",
  "hide",
  "update:visible",
  "resData",
  "refreshHide",
  "clickWithdraw",
  "hideCallRef",
  "checkShareModal",
  "goToPlayNft",
]);

const { visible, withdrawVisible, innerStyle, title } = toRefs(props); // 弹框组件显隐
const wrapperVisible = ref(false); // 弹框外部容器显隐
const wrapperVisible2 = ref(false); // 弹框外部容器显隐
const innerVisible = ref(false); // 弹框中间容器显隐
const showTitle = ref(title.value);

const passwd1 = ref("");
const passwd2 = ref("");
const passwdMsg = ref("");

const withdrawAddress = ref("");
const withdrawCount = ref();
const withdrawPass = ref("");

const nftList = store.getters["auth/getNftList"];

const popupType = ref("");

watch([visible, withdrawVisible], ([newVisible, newWithdrawVisible]) => {
  if (newVisible || newWithdrawVisible) {
    popupType.value = store.state.popupType;

    if (popupType.value === "successWithdraw") {
      wrapperVisible.value = false;
      innerVisible.value = false;
    }

    if (store.state.isLogin === true) {
      showTitle.value = "Please login first";
    } else {
      showTitle.value = title.value;
    }

    wrapperVisible.value = newVisible;
    wrapperVisible2.value = newWithdrawVisible;
    setTimeout(() => {
      innerVisible.value = true;
      emit("afterShow");
    }, 100);
  } else {
    innerVisible.value = false;
    setTimeout(() => {
      wrapperVisible.value = false;
      wrapperVisible2.value = false;
      emit("afterHide");

      if (store.state.isLogin === true) {
        store.state.isLogin = false;
        emit("afterLogin");
      }
    }, 100);
  }
});

const goToPlayNft = () => {
  emit("goToPlayNft");
};

const hide = () => {
  store.state.popupType = "";

  passwd1.value = "";
  passwd2.value = "";
  passwdMsg.value = "";

  withdrawAddress.value = "";
  withdrawCount.value = null;
  withdrawPass.value = "";

  emit("update:visible", false);
  emit("hide");
};

const hideCallRef = () => {
  store.state.popupType = "";

  passwd1.value = "";
  passwd2.value = "";
  passwdMsg.value = "";

  withdrawAddress.value = "";
  withdrawCount.value = null;
  withdrawPass.value = "";

  emit("update:visible", false);
  emit("hideCallRef");
};

const refreshHide = () => {
  store.state.popupType = "";

  passwd1.value = "";
  passwd2.value = "";
  passwdMsg.value = "";

  withdrawAddress.value = "";
  withdrawCount.value = null;
  withdrawPass.value = "";

  emit("update:visible", false);
  emit("refreshHide");
};

const withdrawBtn = () => {
  emit("clickWithdraw");
};

const resData = (res: string) => {
  emit("resData", res);
  emit("update:visible", false);
  emit("hide");
};

const checkShareModal = () => {
  store.state.popupType = "";
  emit("update:visible", false);
  emit("checkShareModal");
};

const containerStyle = computed(() => ({
  transform: innerVisible.value
    ? "translate(-50%, -50%) scale(1,1)"
    : "translate(-50%, -50%) scale(0,0)",
  ...innerStyle.value,
}));

const clickMask = () => {
  hide();
};

const shareTelegram = () => {
  store.state.isPopup = false;
  const referralValue = referral.value;

  if (referralValue) {
    const referralSlice = referralValue.slice(-6);

    const infoShareTelegram = {
      content: {
        objectType: "feed",
        title: `ZeroQuest - 친구초대 이벤트 ${referralSlice}을 입력하세요`,
        description: `PlayStore : https://play.google.com/store/apps/details?id=com.aiblue.zrqst_webview_app&pcampaignid=web_share\nAppStore : https://apps.apple.com/kr/app/%EC%A0%9C%EB%A1%9C%ED%80%98%EC%8A%A4%ED%8A%B8-zeroquest/id6473518337`,
        imageUrl: "https://zeroquest.co.kr/img/logo_big.0bfd62d7.png",
        link: {
          mobileWebUrl: `https://zeroquest.co.kr`,
          webUrl: `https://zeroquest.co.kr`,
        },
        accessToken: accessToken,
      },
    };

    window.flutter_inappwebview
      .callHandler("handleTelegramShareBtn", {
        infoShareTelegram: infoShareTelegram,
      })
      .then((res: any) => {
        checkShareModal();
      });
  } else {
    console.error("store.state.referral is not defined or is empty");
  }
};

const shareKakaoAndroid = () => {
  store.state.isPopup = false;
  const referralValue = referral.value;
  if (referralValue) {
    const referralSlice = referralValue.slice(-6);
    const infoShareKakao = {
      objectType: "feed",
      content: {
        title: `ZeroQuest-친구초대 이벤트 ${referralSlice}을 입력하세요`,
        description: `https://play.google.com/store/apps/details?id=com.aiblue.zrqst_webview_app&pcampaignid=web_share`,
        imageUrl:
          "https://is1-ssl.mzstatic.com/image/thumb/Purple116/v4/0e/fc/34/0efc3403-66bb-e90c-f915-fe30e060c436/AppIcon-0-0-1x_U007emarketing-0-10-0-0-85-220.png/256x256bb.jpg",
        link: {
          mobileWebUrl: `https://zeroquest.co.kr`,
          webUrl: `https://zeroquest.co.kr`,
        },
        accessToken: accessToken,
      },
    };

    // 모바일 버전
    window.flutter_inappwebview
      .callHandler("handleKakaoShareBtn", { infoShareKakao: infoShareKakao })
      .then((res: any) => {
        checkShareModal();
      });
  } else {
    console.error("store.state.referral is not defined or is empty");
  }
};

const shareKakaoIOS = () => {
  store.state.isPopup = false;
  const referralValue = referral.value;
  if (referralValue) {
    const referralSlice = referralValue.slice(-6);
    const infoShareKakao = {
      objectType: "feed",
      content: {
        title: `ZeroQuest-친구초대 이벤트 ${referralSlice}을 입력하세요`,
        description: `https://apps.apple.com/kr/app/%EC%A0%9C%EB%A1%9C%ED%80%98%EC%8A%A4%ED%8A%B8-zeroquest/id6473518337`,
        imageUrl:
          "https://is1-ssl.mzstatic.com/image/thumb/Purple116/v4/0e/fc/34/0efc3403-66bb-e90c-f915-fe30e060c436/AppIcon-0-0-1x_U007emarketing-0-10-0-0-85-220.png/256x256bb.jpg",
        link: {
          mobileWebUrl: `https://zeroquest.co.kr`,
          webUrl: `https://zeroquest.co.kr`,
        },
        accessToken: accessToken,
      },
    };

    // 모바일 버전
    window.flutter_inappwebview
      .callHandler("handleKakaoShareBtn", { infoShareKakao: infoShareKakao })
      .then((res: any) => {
        checkShareModal();
      });
  } else {
    console.error("store.state.referral is not defined or is empty");
  }
};
</script>

<style lang="scss">
.global-modal {
  z-index: 100;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);

  &-container {
    // transition: 0.2s all ease;
    background-color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    // transform: translate(-50%, -70%);
    // border-radius: 10px;
    // padding: 80px 28px 38px 28px;
    // overflow-y: auto;
    // max-height: 90%;

    @media screen and (min-width: 840px) {
      min-width: 400px;
    }

    @media screen and (max-width: 840px) {
      padding: 5rem 2rem;
      width: calc(100% - 60px);
    }

    .title {
      position: absolute;
      top: 27px;
      left: 27px;
      font-size: 20px;
      font-weight: bold;
    }

    .close-icon {
      position: absolute;
      top: 27px;
      right: 27px;
      width: 20px;
      height: 20px;
      cursor: pointer;

      @media screen and (max-width: 840px) {
        position: absolute;
        top: 18px;
        right: 18px;
        width: 15px;
        height: 15px;
        cursor: pointer;
      }
    }

    .error-icon {
      width: 60%;
      height: 60%;
      margin-left: 20%;
    }

    .close-btn {
      background-color: #999;
    }

    .qrcode-bg {
      background-color: #437af0;
    }

    .pass-back-bg {
      background-color: #f6f8f5;
    }

    .pass-bg {
      background-color: #0c5c26;
    }

    .down-btn {
      background-color: #437af0;
      border-radius: 5px;
    }

    .game-btn {
      background-color: #0c5c26;
      border-radius: 5px;
    }

    .off-btn {
      background-color: #ff0000;
      border-radius: 5px;
    }

    .wmuFontFamily {
      font-family: "Hi Melody", sans-serif;
    }

    .wmuBtnColor {
      background-color: #c944aa;
    }

    .text-esg-color {
      color: #24d120;
    }
  }
}
</style>
