import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, withModifiers as _withModifiers, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '@/assets/images/img_close_black.png'
import _imports_1 from '@/assets/images/kakao_logo.png'
import _imports_2 from '@/assets/images/telog.png'
import _imports_3 from '@/assets/images/icon_success.png'
import _imports_4 from '@/assets/images/icon_error.png'


const _hoisted_1 = { class: "flex flex-col justify-center items-center" }
const _hoisted_2 = {
  key: 0,
  class: "flex flex-col justify-center items-center"
}
const _hoisted_3 = { class: "text-2xl text-center" }
const _hoisted_4 = { class: "flex items-center justify-center" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "w-full flex justify-center items-center" }
const _hoisted_7 = {
  key: 1,
  class: "flex flex-col justify-center items-center"
}
const _hoisted_8 = { class: "w-full flex justify-center items-center" }
const _hoisted_9 = { key: 3 }
const _hoisted_10 = { class: "mb-10 text-3xl text-red-700 font-bold" }
const _hoisted_11 = { key: 4 }
const _hoisted_12 = { key: 5 }
const _hoisted_13 = { key: 7 }
const _hoisted_14 = { key: 8 }
const _hoisted_15 = { key: 9 }
const _hoisted_16 = { key: 10 }
const _hoisted_17 = { key: 11 }
const _hoisted_18 = { key: 12 }
const _hoisted_19 = { key: 13 }
const _hoisted_20 = { class: "mb-10 text-3xl font-bold" }
const _hoisted_21 = { key: 14 }
const _hoisted_22 = { key: 15 }
const _hoisted_23 = { key: 16 }
const _hoisted_24 = { key: 17 }
const _hoisted_25 = { key: 18 }
const _hoisted_26 = { class: "font-bold text-2xl pb-8" }
const _hoisted_27 = {
  key: 19,
  class: "mt-10"
}
const _hoisted_28 = { key: 20 }
const _hoisted_29 = { class: "font-bold text-2xl pb-8" }
const _hoisted_30 = { key: 21 }
const _hoisted_31 = { class: "text-2xl text-center" }
const _hoisted_32 = {
  key: 0,
  class: "flex justify-center"
}

import store from "@/store";
import http from "@/api/http";
import { computed, onMounted, ref, toRefs, watch, reactive, nextTick } from "vue";
import { useStore } from "vuex";


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
  visible: {
    type: Boolean,
    default: () => false,
  },
  withdrawVisible: {
    type: Boolean,
    default: () => false,
  },
  innerStyle: {
    type: Object,
    default: () => ({}),
  },
  innerClass: {
    type: String,
    default: () => "",
  },
  popupType: {
    type: String,
    default: () => "Error",
  },
  title: {
    type: String,
    default: () => "",
  },
  showClose: {
    type: Boolean,
    default: () => true,
  },
  preEntryXBtn: {
    type: Boolean,
    default: () => false,
  },
  pointValue: {
    type: String,
    default: () => "",
  },
},
  emits: [
  "afterShow",
  "afterHide",
  "afterLogin",
  "show",
  "hide",
  "update:visible",
  "resData",
  "refreshHide",
  "clickWithdraw",
  "hideCallRef",
  "checkShareModal",
  "goToPlayNft",
],
  setup(__props, { emit: __emit }) {

const vuexStore = useStore();
const referral = computed(() => vuexStore.getters["auth/getReferral"]);
const accessToken = store.getters["auth/getAccessToken"];
const props = __props;

const emit = __emit;

const { visible, withdrawVisible, innerStyle, title } = toRefs(props); // 弹框组件显隐
const wrapperVisible = ref(false); // 弹框外部容器显隐
const wrapperVisible2 = ref(false); // 弹框外部容器显隐
const innerVisible = ref(false); // 弹框中间容器显隐
const showTitle = ref(title.value);

const passwd1 = ref("");
const passwd2 = ref("");
const passwdMsg = ref("");

const withdrawAddress = ref("");
const withdrawCount = ref();
const withdrawPass = ref("");

const nftList = store.getters["auth/getNftList"];

const popupType = ref("");

watch([visible, withdrawVisible], ([newVisible, newWithdrawVisible]) => {
  if (newVisible || newWithdrawVisible) {
    popupType.value = store.state.popupType;

    if (popupType.value === "successWithdraw") {
      wrapperVisible.value = false;
      innerVisible.value = false;
    }

    if (store.state.isLogin === true) {
      showTitle.value = "Please login first";
    } else {
      showTitle.value = title.value;
    }

    wrapperVisible.value = newVisible;
    wrapperVisible2.value = newWithdrawVisible;
    setTimeout(() => {
      innerVisible.value = true;
      emit("afterShow");
    }, 100);
  } else {
    innerVisible.value = false;
    setTimeout(() => {
      wrapperVisible.value = false;
      wrapperVisible2.value = false;
      emit("afterHide");

      if (store.state.isLogin === true) {
        store.state.isLogin = false;
        emit("afterLogin");
      }
    }, 100);
  }
});

const goToPlayNft = () => {
  emit("goToPlayNft");
};

const hide = () => {
  store.state.popupType = "";

  passwd1.value = "";
  passwd2.value = "";
  passwdMsg.value = "";

  withdrawAddress.value = "";
  withdrawCount.value = null;
  withdrawPass.value = "";

  emit("update:visible", false);
  emit("hide");
};

const hideCallRef = () => {
  store.state.popupType = "";

  passwd1.value = "";
  passwd2.value = "";
  passwdMsg.value = "";

  withdrawAddress.value = "";
  withdrawCount.value = null;
  withdrawPass.value = "";

  emit("update:visible", false);
  emit("hideCallRef");
};

const refreshHide = () => {
  store.state.popupType = "";

  passwd1.value = "";
  passwd2.value = "";
  passwdMsg.value = "";

  withdrawAddress.value = "";
  withdrawCount.value = null;
  withdrawPass.value = "";

  emit("update:visible", false);
  emit("refreshHide");
};

const withdrawBtn = () => {
  emit("clickWithdraw");
};

const resData = (res: string) => {
  emit("resData", res);
  emit("update:visible", false);
  emit("hide");
};

const checkShareModal = () => {
  store.state.popupType = "";
  emit("update:visible", false);
  emit("checkShareModal");
};

const containerStyle = computed(() => ({
  transform: innerVisible.value
    ? "translate(-50%, -50%) scale(1,1)"
    : "translate(-50%, -50%) scale(0,0)",
  ...innerStyle.value,
}));

const clickMask = () => {
  hide();
};

const shareTelegram = () => {
  store.state.isPopup = false;
  const referralValue = referral.value;

  if (referralValue) {
    const referralSlice = referralValue.slice(-6);

    const infoShareTelegram = {
      content: {
        objectType: "feed",
        title: `ZeroQuest - 친구초대 이벤트 ${referralSlice}을 입력하세요`,
        description: `PlayStore : https://play.google.com/store/apps/details?id=com.aiblue.zrqst_webview_app&pcampaignid=web_share\nAppStore : https://apps.apple.com/kr/app/%EC%A0%9C%EB%A1%9C%ED%80%98%EC%8A%A4%ED%8A%B8-zeroquest/id6473518337`,
        imageUrl: "https://zeroquest.co.kr/img/logo_big.0bfd62d7.png",
        link: {
          mobileWebUrl: `https://zeroquest.co.kr`,
          webUrl: `https://zeroquest.co.kr`,
        },
        accessToken: accessToken,
      },
    };

    window.flutter_inappwebview
      .callHandler("handleTelegramShareBtn", {
        infoShareTelegram: infoShareTelegram,
      })
      .then((res: any) => {
        checkShareModal();
      });
  } else {
    console.error("store.state.referral is not defined or is empty");
  }
};

const shareKakaoAndroid = () => {
  store.state.isPopup = false;
  const referralValue = referral.value;
  if (referralValue) {
    const referralSlice = referralValue.slice(-6);
    const infoShareKakao = {
      objectType: "feed",
      content: {
        title: `ZeroQuest-친구초대 이벤트 ${referralSlice}을 입력하세요`,
        description: `https://play.google.com/store/apps/details?id=com.aiblue.zrqst_webview_app&pcampaignid=web_share`,
        imageUrl:
          "https://is1-ssl.mzstatic.com/image/thumb/Purple116/v4/0e/fc/34/0efc3403-66bb-e90c-f915-fe30e060c436/AppIcon-0-0-1x_U007emarketing-0-10-0-0-85-220.png/256x256bb.jpg",
        link: {
          mobileWebUrl: `https://zeroquest.co.kr`,
          webUrl: `https://zeroquest.co.kr`,
        },
        accessToken: accessToken,
      },
    };

    // 모바일 버전
    window.flutter_inappwebview
      .callHandler("handleKakaoShareBtn", { infoShareKakao: infoShareKakao })
      .then((res: any) => {
        checkShareModal();
      });
  } else {
    console.error("store.state.referral is not defined or is empty");
  }
};

const shareKakaoIOS = () => {
  store.state.isPopup = false;
  const referralValue = referral.value;
  if (referralValue) {
    const referralSlice = referralValue.slice(-6);
    const infoShareKakao = {
      objectType: "feed",
      content: {
        title: `ZeroQuest-친구초대 이벤트 ${referralSlice}을 입력하세요`,
        description: `https://apps.apple.com/kr/app/%EC%A0%9C%EB%A1%9C%ED%80%98%EC%8A%A4%ED%8A%B8-zeroquest/id6473518337`,
        imageUrl:
          "https://is1-ssl.mzstatic.com/image/thumb/Purple116/v4/0e/fc/34/0efc3403-66bb-e90c-f915-fe30e060c436/AppIcon-0-0-1x_U007emarketing-0-10-0-0-85-220.png/256x256bb.jpg",
        link: {
          mobileWebUrl: `https://zeroquest.co.kr`,
          webUrl: `https://zeroquest.co.kr`,
        },
        accessToken: accessToken,
      },
    };

    // 모바일 버전
    window.flutter_inappwebview
      .callHandler("handleKakaoShareBtn", { infoShareKakao: infoShareKakao })
      .then((res: any) => {
        checkShareModal();
      });
  } else {
    console.error("store.state.referral is not defined or is empty");
  }
};

return (_ctx: any,_cache: any) => {
  return (wrapperVisible.value || wrapperVisible2.value)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "global-modal",
        onClick: clickMask
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(['global-modal-container', __props.innerClass]),
          style: _normalizeStyle(containerStyle.value),
          onClick: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["stop"]))
        }, [
          (popupType.value === 'shareDone')
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                class: "close-icon",
                src: _imports_0,
                onClick: hideCallRef
              }))
            : (__props.preEntryXBtn)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 1,
                  class: "close-icon",
                  src: _imports_0,
                  onClick: goToPlayNft
                }))
              : (__props.showClose)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 2,
                    class: "close-icon",
                    src: _imports_0,
                    onClick: hide
                  }))
                : (_openBlock(), _createElementBlock("img", {
                    key: 3,
                    class: "close-icon",
                    src: _imports_0,
                    onClick: refreshHide
                  })),
          _createElementVNode("div", _hoisted_1, [
            (popupType.value === 'game_install')
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _cache[9] || (_cache[9] = _createElementVNode("div", { class: "h-5" }, null, -1)),
                  _createElementVNode("div", _hoisted_3, [
                    _cache[5] || (_cache[5] = _createTextVNode(" NFT 를 활성화하기 위해서는")),
                    _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1)),
                    _createTextVNode(" " + _toDisplayString(_unref(nftList)[_unref(store).state.nftId].name) + " App 을", 1),
                    _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1)),
                    _cache[8] || (_cache[8] = _createTextVNode("설치해야 합니다. "))
                  ]),
                  _cache[10] || (_cache[10] = _createElementVNode("div", { class: "h-10" }, null, -1)),
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("img", {
                      src: _unref(nftList)[_unref(store).state.nftId].image
                    }, null, 8, _hoisted_5)
                  ]),
                  _cache[11] || (_cache[11] = _createElementVNode("div", { class: "h-10" }, null, -1)),
                  _cache[12] || (_cache[12] = _createElementVNode("div", { class: "text-xl text-center" }, [
                    _createTextVNode(" 연동되는 어플리케이션은 설치 후 동일한"),
                    _createElementVNode("br"),
                    _createTextVNode("구글 로그인을 사용해야 합니다. ")
                  ], -1)),
                  _cache[13] || (_cache[13] = _createElementVNode("div", { class: "h-10" }, null, -1)),
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("button", {
                      class: "wp-40 p-2 font-semibold text-2xl text-white game-btn",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (resData(_unref(store).state.nftId.toString())))
                    }, " 설치하기 ")
                  ])
                ]))
              : _createCommentVNode("", true),
            (popupType.value === 'game_off')
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _cache[14] || (_cache[14] = _createElementVNode("div", { class: "h-5" }, null, -1)),
                  _cache[15] || (_cache[15] = _createElementVNode("div", { class: "text-2xl text-center font-medium" }, [
                    _createTextVNode(" NFT 를 "),
                    _createElementVNode("span", { style: {"color":"red"} }, "OFF"),
                    _createTextVNode(" 하면"),
                    _createElementVNode("br"),
                    _createTextVNode("탄소절감 데이터가 적용"),
                    _createElementVNode("br"),
                    _createTextVNode("되지 않습니다. ")
                  ], -1)),
                  _cache[16] || (_cache[16] = _createElementVNode("div", { class: "h-10" }, null, -1)),
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("button", {
                      class: "wp-60 p-2 font-semibold text-2xl text-white off-btn",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (resData('OFF')))
                    }, " OFF ")
                  ])
                ]))
              : _createCommentVNode("", true),
            (popupType.value === 'duplicate_nft_buy')
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                  _cache[22] || (_cache[22] = _createElementVNode("div", null, [
                    _createElementVNode("div", { class: "mb-16 text-3xl font-bold text-black" }, [
                      _createTextVNode(" 탄소감축을 위한 "),
                      _createElementVNode("br"),
                      _createTextVNode(" NFT는 반복 구매할 수 없습니다. ")
                    ])
                  ], -1)),
                  _cache[23] || (_cache[23] = _createElementVNode("div", null, [
                    _createElementVNode("div", { class: "font-semibold text-xl text-black text-2xl" }, [
                      _createTextVNode(" 탄소감축 \"제로퀘스트\"를 "),
                      _createElementVNode("br"),
                      _createTextVNode(" 친구들에게 추천하면"),
                      _createElementVNode("br"),
                      _createElementVNode("span", { class: "text-esg-color font-bold" }, "50ESG point"),
                      _createTextVNode(" 를 드립니다 ")
                    ])
                  ], -1)),
                  _createElementVNode("div", { class: "mt-7 mb-4" }, [
                    _createElementVNode("button", { type: "button" }, [
                      _createElementVNode("a", {
                        id: "kakao-link-btn",
                        onClick: shareKakaoAndroid
                      }, _cache[17] || (_cache[17] = [
                        _createElementVNode("img", {
                          src: _imports_1,
                          alt: "카카오톡 공유하기"
                        }, null, -1)
                      ])),
                      _cache[18] || (_cache[18] = _createElementVNode("div", { class: "text-lg font-medium" }, "Android", -1))
                    ]),
                    _createElementVNode("button", {
                      type: "button",
                      class: "ml-2"
                    }, [
                      _createElementVNode("a", {
                        id: "kakao-link-btn",
                        onClick: shareKakaoIOS
                      }, _cache[19] || (_cache[19] = [
                        _createElementVNode("img", {
                          src: _imports_1,
                          alt: "카카오톡 공유하기"
                        }, null, -1)
                      ])),
                      _cache[20] || (_cache[20] = _createElementVNode("div", { class: "text-lg font-medium" }, "Ios", -1))
                    ]),
                    _createElementVNode("button", {
                      type: "button",
                      class: "sns_btn",
                      onClick: shareTelegram
                    }, _cache[21] || (_cache[21] = [
                      _createElementVNode("img", {
                        src: _imports_2,
                        alt: "텔레그램 공유하기"
                      }, null, -1)
                    ]))
                  ])
                ], 64))
              : _createCommentVNode("", true),
            (popupType.value === 'successMinting')
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _cache[24] || (_cache[24] = _createElementVNode("div", { class: "mb-10 flex justify-center" }, [
                    _createElementVNode("img", {
                      class: "wp-30",
                      src: _imports_3
                    })
                  ], -1)),
                  _cache[25] || (_cache[25] = _createElementVNode("div", { class: "mb-10 text-3xl font-bold" }, "NFT 민팅 성공!", -1)),
                  _createElementVNode("div", _hoisted_10, _toDisplayString(_unref(title)), 1),
                  _createElementVNode("div", null, [
                    _createElementVNode("button", {
                      class: "w-48 h-12 font-semibold text-white text-xl rounded close-btn",
                      onClick: refreshHide
                    }, " 확인 ")
                  ])
                ]))
              : _createCommentVNode("", true),
            (popupType.value === 'serviceChecking')
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  _cache[26] || (_cache[26] = _createElementVNode("div", { class: "mb-10 text-3xl font-bold" }, "구입이 완료되었습니다.", -1)),
                  _createElementVNode("div", null, [
                    _createElementVNode("button", {
                      class: "w-48 h-12 font-semibold text-white text-xl rounded close-btn",
                      onClick: hide
                    }, " 확인 ")
                  ])
                ]))
              : _createCommentVNode("", true),
            (popupType.value === 'VerificationCmpleted')
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                  _cache[27] || (_cache[27] = _createElementVNode("div", { class: "mb-10 text-3xl font-bold" }, [
                    _createTextVNode(" 본인인증을 완료한뒤"),
                    _createElementVNode("br"),
                    _createTextVNode(" 출금 신청하기를 진행해주세요. ")
                  ], -1)),
                  _createElementVNode("div", null, [
                    _createElementVNode("button", {
                      class: "w-48 h-12 font-semibold text-white text-xl rounded close-btn",
                      onClick: hide
                    }, " 확인 ")
                  ])
                ]))
              : _createCommentVNode("", true),
            (popupType.value === 'withDraw')
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 6 }, [
                  _cache[29] || (_cache[29] = _createElementVNode("div", { class: "mb-10 text-3xl font-bold" }, "정말 탈퇴하시겠어요?", -1)),
                  _createElementVNode("div", null, [
                    _cache[28] || (_cache[28] = _createElementVNode("div", { class: "mb-10 text-2xl font-semibold" }, [
                      _createTextVNode(" 탈퇴시, 즉시 탈퇴가 완료되어 "),
                      _createElementVNode("br"),
                      _createTextVNode(" 쌓인 포인트와 NFT정보가 삭제되고"),
                      _createElementVNode("br"),
                      _createTextVNode(" 복구가 불가능합니다. ")
                    ], -1)),
                    _createElementVNode("div", { class: "flex justify-between" }, [
                      _createElementVNode("button", {
                        class: "w-44 h-12 font-semibold text-xl rounded close-btn bg-white text-green-800 border border-gray-300 border-solid",
                        onClick: hide
                      }, " 취소 "),
                      _createElementVNode("button", {
                        class: "w-44 h-12 font-semibold text-white text-xl rounded close-btn bg-green-700",
                        onClick: withdrawBtn
                      }, " 탈퇴하기 ")
                    ])
                  ])
                ], 64))
              : _createCommentVNode("", true),
            (popupType.value === 'successWithdraw')
              ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                  _cache[30] || (_cache[30] = _createElementVNode("div", { class: "mb-4 text-2xl font-bold" }, [
                    _createTextVNode("회원탈퇴가 완료되었습니다."),
                    _createElementVNode("br")
                  ], -1)),
                  _cache[31] || (_cache[31] = _createElementVNode("div", { class: "mb-10 text-xl font-semibold" }, [
                    _createTextVNode(" 아쉽지만 회원님과의 "),
                    _createElementVNode("br"),
                    _createTextVNode(" 다음 만남을 기대하겠습니다."),
                    _createElementVNode("br")
                  ], -1)),
                  _createElementVNode("div", null, [
                    _createElementVNode("button", {
                      class: "w-48 h-12 font-semibold text-white text-xl rounded close-btn",
                      onClick: refreshHide
                    }, " 확인 ")
                  ])
                ]))
              : _createCommentVNode("", true),
            (popupType.value === 'esgPointCharge')
              ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                  _cache[32] || (_cache[32] = _createElementVNode("div", { class: "mb-10 text-2xl font-bold" }, [
                    _createTextVNode(" ESG point 충전하기는 서비스 준비중입니다. "),
                    _createElementVNode("br"),
                    _createTextVNode(" 충전해서 사용하기 보다는 탄소감축을 통해서 "),
                    _createElementVNode("br"),
                    _createTextVNode(" ESG point를 수집하세요 ")
                  ], -1)),
                  _createElementVNode("div", null, [
                    _createElementVNode("button", {
                      class: "w-48 h-12 font-semibold text-white text-xl rounded close-btn",
                      onClick: hide
                    }, " 확인 ")
                  ])
                ]))
              : _createCommentVNode("", true),
            (popupType.value === 'InsufficientPoints')
              ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                  _cache[33] || (_cache[33] = _createElementVNode("div", { class: "mb-10 text-3xl font-bold" }, "포인트가 부족합니다", -1)),
                  _createElementVNode("div", null, [
                    _createElementVNode("button", {
                      class: "w-48 h-12 font-semibold text-white text-xl rounded close-btn",
                      onClick: hide
                    }, " 확인 ")
                  ])
                ]))
              : _createCommentVNode("", true),
            (popupType.value === 'PreparingForService')
              ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                  _cache[34] || (_cache[34] = _createElementVNode("div", { class: "mb-10 text-3xl font-bold" }, "서비스 준비중", -1)),
                  _createElementVNode("div", null, [
                    _createElementVNode("button", {
                      class: "w-48 h-12 font-semibold text-white text-xl rounded close-btn",
                      onClick: hide
                    }, " 확인 ")
                  ])
                ]))
              : _createCommentVNode("", true),
            (popupType.value === 'pandaNFTSoldOut')
              ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                  _cache[35] || (_cache[35] = _createElementVNode("div", { class: "mb-10 text-3xl font-bold" }, [
                    _createTextVNode(" NFT 판매가 완료됐습니다. "),
                    _createElementVNode("br"),
                    _createElementVNode("div", { class: "text-2xl font-semibold mt-5" }, [
                      _createTextVNode(" 다음 스페셜 NFT를 기대해 주세요."),
                      _createElementVNode("br"),
                      _createTextVNode(" 환경과 멸종 위기 동물에 "),
                      _createElementVNode("br"),
                      _createTextVNode(" 관심 가져주셔서 감사합니다."),
                      _createElementVNode("br")
                    ])
                  ], -1)),
                  _createElementVNode("div", null, [
                    _createElementVNode("button", {
                      class: "w-48 h-12 font-semibold text-white text-xl rounded close-btn",
                      onClick: hide
                    }, " 확인 ")
                  ])
                ]))
              : _createCommentVNode("", true),
            (popupType.value === 'mongoliaNFTSoldOut')
              ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                  _cache[36] || (_cache[36] = _createElementVNode("div", { class: "mb-10 text-3xl font-bold" }, [
                    _createTextVNode(" 몽골나무 NFT 판매가 완료됐습니다. "),
                    _createElementVNode("br"),
                    _createElementVNode("div", { class: "text-2xl font-semibold mt-5" }, [
                      _createTextVNode(" 다음 몽골나무 NFT를 기대해 주세요."),
                      _createElementVNode("br"),
                      _createTextVNode(" 관심 가져주셔서 감사합니다."),
                      _createElementVNode("br")
                    ])
                  ], -1)),
                  _createElementVNode("div", null, [
                    _createElementVNode("button", {
                      class: "w-48 h-12 font-semibold text-white text-xl rounded close-btn",
                      onClick: hide
                    }, " 확인 ")
                  ])
                ]))
              : _createCommentVNode("", true),
            (popupType.value === 'informationMessage')
              ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                  _createElementVNode("div", _hoisted_20, _toDisplayString(showTitle.value), 1),
                  _createElementVNode("div", null, [
                    (__props.showClose)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 0,
                          class: "w-48 h-12 font-semibold text-white text-xl rounded close-btn",
                          onClick: hide
                        }, " 확인 "))
                      : (_openBlock(), _createElementBlock("button", {
                          key: 1,
                          class: "w-48 h-12 font-semibold text-white text-xl rounded close-btn",
                          onClick: refreshHide
                        }, " 확인 "))
                  ])
                ]))
              : _createCommentVNode("", true),
            (popupType.value === 'tree_nft')
              ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                  _cache[37] || (_cache[37] = _createElementVNode("div", null, [
                    _createElementVNode("img", {
                      class: "error-icon",
                      src: _imports_4
                    })
                  ], -1)),
                  _cache[38] || (_cache[38] = _createElementVNode("div", { class: "mb-10 text-3xl font-bold" }, "Coming soon.", -1)),
                  _createElementVNode("div", null, [
                    _createElementVNode("button", {
                      class: "w-48 h-12 font-semibold text-white text-xl rounded close-btn",
                      onClick: hide
                    }, " 확인 ")
                  ])
                ]))
              : _createCommentVNode("", true),
            (popupType.value === 'successReferral')
              ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                  _cache[39] || (_cache[39] = _createElementVNode("div", { class: "mb-10 flex justify-center" }, [
                    _createElementVNode("img", {
                      class: "wp-30",
                      src: _imports_3
                    })
                  ], -1)),
                  _cache[40] || (_cache[40] = _createElementVNode("div", { class: "mb-10 text-3xl font-bold" }, "추천인 코드 입력 완료", -1)),
                  _cache[41] || (_cache[41] = _createElementVNode("div", { class: "mb-10 text-2xl font-bold" }, "추천인에게 1000ESG point가 적립되었습니다.", -1)),
                  _createElementVNode("div", null, [
                    _createElementVNode("button", {
                      class: "w-48 h-12 font-semibold text-white text-xl rounded close-btn",
                      onClick: hide
                    }, " 확인 ")
                  ])
                ]))
              : _createCommentVNode("", true),
            (popupType.value === 'shareDone')
              ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                  _cache[42] || (_cache[42] = _createElementVNode("div", { class: "font-bold text-2xl pb-8" }, "공유하기를 하였습니다.", -1)),
                  _createElementVNode("div", null, [
                    _createElementVNode("button", {
                      class: "w-48 h-12 font-semibold text-white text-xl rounded close-btn",
                      onClick: hideCallRef
                    }, " Closed ")
                  ])
                ]))
              : _createCommentVNode("", true),
            (popupType.value === 'shareSuccess')
              ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                  _cache[43] || (_cache[43] = _createElementVNode("div", { class: "mb-10 flex justify-center" }, [
                    _createElementVNode("img", {
                      class: "wp-30",
                      src: _imports_3
                    })
                  ], -1)),
                  _cache[44] || (_cache[44] = _createElementVNode("div", { class: "font-bold text-2xl pb-8" }, "공유하기를 성공하였습니다.", -1)),
                  _createElementVNode("div", null, [
                    _createElementVNode("button", {
                      class: "w-48 h-12 font-semibold text-white text-xl rounded close-btn",
                      onClick: refreshHide
                    }, " Closed ")
                  ])
                ]))
              : _createCommentVNode("", true),
            (popupType.value === 'pointConversion')
              ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                  _cache[45] || (_cache[45] = _createElementVNode("div", { class: "mb-10 flex justify-center" }, [
                    _createElementVNode("img", {
                      class: "wp-30",
                      src: _imports_3
                    })
                  ], -1)),
                  _createElementVNode("div", _hoisted_26, "계단오르기 " + _toDisplayString(__props.pointValue) + " 포인트 전환 완료", 1),
                  _createElementVNode("div", null, [
                    _createElementVNode("button", {
                      class: "w-48 h-12 font-semibold text-white text-xl rounded close-btn",
                      onClick: refreshHide
                    }, " Closed ")
                  ])
                ]))
              : _createCommentVNode("", true),
            (popupType.value === 'preEntryCompleted')
              ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                  _cache[46] || (_cache[46] = _createStaticVNode("<div class=\"text-2xl\"><span class=\"font-semibold text-[#24d120]\">입장신청 NFT 발급이 완료</span><span class=\"font-semibold\">되었습니다.</span></div><div class=\"py-3 text-xl\"><span class=\"font-bold border-b-[1px] border-black\">PlayNFT</span><span class=\"text-gray-500\">에서 신청결과를 확인하실 수 있습니다.</span></div>", 2)),
                  _createElementVNode("div", { class: "mt-8" }, [
                    _createElementVNode("button", {
                      class: "w-48 h-12 font-semibold text-white text-xl rounded bg-[#0c5c26]",
                      onClick: goToPlayNft
                    }, " PlayNFT 바로가기 ")
                  ])
                ]))
              : _createCommentVNode("", true),
            (popupType.value === 'service')
              ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                  _createElementVNode("div", _hoisted_29, _toDisplayString(_unref(title)), 1),
                  _createElementVNode("div", null, [
                    _createElementVNode("button", {
                      class: "w-48 h-12 font-semibold text-white text-xl rounded close-btn",
                      onClick: hide
                    }, " 확인 ")
                  ])
                ]))
              : _createCommentVNode("", true),
            (popupType.value === 'comming_soon_ai')
              ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                  _cache[47] || (_cache[47] = _createElementVNode("div", null, [
                    _createElementVNode("img", {
                      class: "error-icon",
                      src: _imports_4
                    })
                  ], -1)),
                  _cache[48] || (_cache[48] = _createElementVNode("div", { class: "h-10" }, null, -1)),
                  _cache[49] || (_cache[49] = _createElementVNode("div", { class: "mb-10 text-3xl font-bold" }, "서비스 점검 중", -1)),
                  _createElementVNode("div", null, [
                    _createElementVNode("button", {
                      class: "w-48 h-12 font-semibold text-white text-xl rounded close-btn",
                      onClick: hide
                    }, " 확인 ")
                  ])
                ]))
              : _createCommentVNode("", true),
            (
            popupType.value !== 'game_install' &&
            popupType.value !== 'game_off' &&
            popupType.value !== 'duplicate_nft_buy' &&
            popupType.value !== 'successMinting' &&
            popupType.value !== 'serviceChecking' &&
            popupType.value !== 'InsufficientPoints' &&
            popupType.value !== 'VerificationCmpleted' &&
            popupType.value !== 'esgPointCharge' &&
            popupType.value !== 'PreparingForService' &&
            popupType.value !== 'informationMessage' &&
            popupType.value !== 'tree_nft' &&
            popupType.value !== 'SelectionIsNotComplete' &&
            popupType.value !== 'SelectionIsComplete' &&
            popupType.value !== 'SelectionIsCompleted' &&
            popupType.value !== 'VoteIsOver' &&
            popupType.value !== 'withDraw' &&
            popupType.value !== 'successWithdraw' &&
            popupType.value !== 'pandaNFTSoldOut' &&
            popupType.value !== 'successReferral' &&
            popupType.value !== 'wmuVoteIsOver' &&
            popupType.value !== 'shareDone' &&
            popupType.value !== 'shareSuccess' &&
            popupType.value !== 'pointConversion' &&
            popupType.value !== 'preEntryCompleted' &&
            popupType.value !== 'pleaseTag' &&
            popupType.value !== 'service' &&
            popupType.value !== 'comming_soon_ai'
          )
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 22 }, [
                  _cache[51] || (_cache[51] = _createElementVNode("div", null, [
                    _createElementVNode("img", {
                      class: "error-icon",
                      src: _imports_4
                    })
                  ], -1)),
                  _cache[52] || (_cache[52] = _createElementVNode("div", { class: "h-10" }, null, -1)),
                  _cache[53] || (_cache[53] = _createElementVNode("div", { class: "h-10" }, null, -1)),
                  _createElementVNode("div", _hoisted_31, _toDisplayString(showTitle.value), 1),
                  _cache[54] || (_cache[54] = _createElementVNode("div", { class: "h-10" }, null, -1)),
                  (popupType.value !== 'message')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
                        _createElementVNode("div", null, [
                          _createElementVNode("button", {
                            class: "w-36 h-12 font-semibold text-white text-xl rounded close-btn",
                            onClick: _cache[2] || (_cache[2] = ($event: any) => (resData('yes')))
                          }, " YES ")
                        ]),
                        _cache[50] || (_cache[50] = _createElementVNode("div", { class: "w-20" }, null, -1)),
                        _createElementVNode("div", null, [
                          _createElementVNode("button", {
                            class: "w-36 h-12 font-semibold text-white text-xl rounded close-btn",
                            onClick: _cache[3] || (_cache[3] = ($event: any) => (resData('no')))
                          }, " NO ")
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ], 64))
              : _createCommentVNode("", true)
          ])
        ], 6)
      ]))
    : _createCommentVNode("", true)
}
}

})