import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "fixed-bottom" }

import { ref } from "vue";
import { useRouter } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'FooterView',
  setup(__props) {

const router = useRouter();

const isPopup = ref(false);

const closeModal = () => {
  isPopup.value = false;
};

const movePage = (page: string) => {
  router.push(page);
};

// const movePage = (page: string) => {
// 	if (page === '/Challenge') {
// 		vuexStore.state.popupType = 'message';
// 		isPopup.value = true;
// 	} else {
// 		router.push(page);
// 	}
// };

return (_ctx: any,_cache: any) => {
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[8] || (_cache[8] = _createElementVNode("div", { class: "h-10" }, null, -1)),
    _cache[9] || (_cache[9] = _createElementVNode("div", { class: "h-10" }, null, -1)),
    _cache[10] || (_cache[10] = _createElementVNode("div", { class: "h-10" }, null, -1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("a", {
        class: _normalizeClass({ oPage: _ctx.$route.name === 'home' }),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (movePage('/home')))
      }, "Home", 2),
      _createElementVNode("a", {
        class: _normalizeClass({ oPage: _ctx.$route.name === 'zeronft' }),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (movePage('/zeronft')))
      }, "ZeroNFT", 2),
      _createElementVNode("a", {
        class: _normalizeClass({
        oPage: _ctx.$route.name === 'onft' || _ctx.$route.name === 'onft-detail',
      }),
        onClick: _cache[2] || (_cache[2] = ($event: any) => (movePage('/onft')))
      }, "PlayNFT", 2),
      _createElementVNode("a", {
        class: _normalizeClass({ oPage: _ctx.$route.name === 'mywallet' }),
        onClick: _cache[3] || (_cache[3] = ($event: any) => (movePage('/mywallet')))
      }, _cache[6] || (_cache[6] = [
        _createTextVNode("My"),
        _createElementVNode("br", null, null, -1),
        _createTextVNode("Point")
      ]), 2),
      _createElementVNode("a", {
        class: _normalizeClass({ oPage: _ctx.$route.name === 'market' }),
        onClick: _cache[4] || (_cache[4] = ($event: any) => (movePage('/market')))
      }, _cache[7] || (_cache[7] = [
        _createTextVNode("Eco"),
        _createElementVNode("br", null, null, -1),
        _createTextVNode("Market")
      ]), 2),
      _createElementVNode("a", {
        class: _normalizeClass({
        oPage: _ctx.$route.name === 'challenge',
      }),
        onClick: _cache[5] || (_cache[5] = ($event: any) => (movePage('/challenge')))
      }, "Challenge", 2)
    ]),
    _createVNode(_component_Modal, {
      visible: isPopup.value,
      title: "서비스 준비중",
      onHide: closeModal
    }, null, 8, ["visible"])
  ], 64))
}
}

})