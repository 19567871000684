import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/images/logo_big.png'
import _imports_1 from '@/assets/images/kakaoo.png'
import _imports_2 from '@/assets/images/applee.png'
import _imports_3 from '@/assets/images/daeguLogin.png'


const _hoisted_1 = { class: "flex flex-col justify-center items-center" }
const _hoisted_2 = { class: "flex flex-col justify-center items-center" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = { key: 3 }

import axios from "axios";
import router from "@/router";
import store from "@/store";
import openSSLCrypto from "@/utils/openSSLCrypto";
import { onMounted, ref } from "vue";
import { prepareDaeguLoginApi, daeguLoginApi } from "@/api/auth";


export default /*@__PURE__*/_defineComponent({
  __name: 'LoginView',
  setup(__props) {

const showMode = ref(router.currentRoute.value.query.showMode);
const isLogin = ref(router.currentRoute.value.query.isLogin);

const platform = ref("");

onMounted(() => {
  if (store.state.showMode === "webview" || showMode.value === "webview") {
    store.state.showMode = "webview";

    if (isLogin.value == 1) {
      store.state.isLoading = true;
      window.Java.jsLogin();
    }
  }

  detectDevice();
});

const detectDevice = () => {
  // 기기 구분 코드
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // iOS 검사 (iPhone, iPad, iPod)
  if (/iPhone|iPad|iPod/.test(userAgent)) {
    console.log("ios");
    platform.value = "iOS";
    localStorage.setItem("platform", "iOS");
  }

  // Mac OS X 검사
  else if (/Mac OS X/.test(userAgent)) {
    console.log("mac");
    platform.value = "Mac OS X";
    localStorage.setItem("platform", "Mac OS X");
  }
  // Android 검사
  else if (/Android/.test(userAgent)) {
    console.log("android");
    platform.value = "Android";
    localStorage.setItem("platform", "Android");
  }

  // Window NT 검사
  else if (/Windows NT/.test(userAgent)) {
    platform.value = "Windows NT";
    localStorage.setItem("platform", "Windows NT");
  }
};

const loginWithApple = () => {
  window.flutter_inappwebview
    .callHandler("handleAppleLoginBtn")
    .then((res: any) => {
      if (res) {
        const email = res.email;
        const name = res.familyName + res.givenName;
        const id = res.userIdentifier;
        const fcmToken = res.fcmToken;
        login(email, name, id, "apple", fcmToken);
      } else {
        console.error("Invalid user information received:", res);
      }
    })
    .catch((error: any) => {
      console.error("Error fetching user info:", error);
    });
};

const loginWithKakao = () => {
  if (platform.value === "Android" || platform.value === "iOS") {
    window.flutter_inappwebview
      .callHandler("handleKakaoLoginBtn")
      .then((res: any) => {
        if (res) {
          const email = res.kakao_account.email;
          const name = res.kakao_account.profile.nickname;
          const id = res.id;
          const fcmToken = res.fcm_token;

          login(email, name, id, "kakao", fcmToken);
        } else {
          console.error("Invalid user information received:", res);
        }
      })
      .catch((error: any) => {
        console.error("Error fetching user info:", error);
      });
  } else {
    window.Kakao.Auth.login({
      scope: "profile_nickname, profile_image, account_email",
      success: getProfile,
    });
  }
};

// polling 함수 정의
const pollingDaeguLogin = async (sessionKey: string, loginContext: object, maxRetries = 10) => {
  let retryCount = 0;

  const poll = async () => {
    try {
      const response = await daeguLoginApi(sessionKey, loginContext);

      if (response?.data?.status === 1) {
        store.dispatch("auth/updateDaeguLogin", response);

        return; // 성공 시 폴링 종료
      }

      retryCount++;
      if (retryCount >= maxRetries) {
        console.error("최대 재시도 횟수를 초과했습니다.");
        throw new Error("로그인 실패");
      }

      // 3초 후 재귀적으로 호출
      setTimeout(poll, 3000);
    } catch (error) {
      console.error("Error during polling:", error);
      retryCount++;
      if (retryCount >= maxRetries) {
        console.error("최대 재시도 횟수를 초과했습니다.");
        alert("로그인 시간이 초과 되었습니다. 다시 시도해주세요.");
        throw new Error("로그인 실패");
      }
      setTimeout(poll, 3000); // 에러 발생 시 일정 시간 후 재시도
    }
  };

  await poll();
};

const loginWithDaegu = async () => {
  store.state.isLoading = true;

  try {
    const response = await prepareDaeguLoginApi();

    if (response?.data?.status === 1) {
      // 로그인에 필요한 데이터 정의
      const loginContext = {
        social: "dadaegu",
        os: platform.value,
        timestamp: Date.now(),
      };

      const data = response.data.data;
      const sessionKey = data.sessionKey;

      pollingDaeguLogin(sessionKey, loginContext);

      window.flutter_inappwebview.callHandler("handleDaeguLoginBtn", {
        flag: data.flag,
        sessionKey: sessionKey,
        requiredVC: data.requiredVC,
        subVC: data.subVC,
        url: data.url,
        siteId: data.siteId,
      });
    }
  } catch (error) {
    console.error(error);
  } finally {
    store.state.isLoading = false;
  }
};

const getProfile = (authObj: any) => {
  const access_token = authObj.access_token;

  axios({
    method: "GET",
    url: "https://kapi.kakao.com/v2/user/me",
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  })
    .then((userInfo) => {
      if (userInfo.data && userInfo.data.kakao_account && userInfo.data.kakao_account.profile) {
        const email = userInfo.data.kakao_account.email;
        const name = userInfo.data.kakao_account.profile.nickname;
        const id = userInfo.data.id;

        login(email, name, id, "kakao");
      } else {
        console.error("Invalid user information received:", userInfo);
      }
    })
    .catch((error) => {
      console.error("Error fetching user info:", error);
    });
};

const login = async (email: any, name: any, id: any, social: string, fcmToken: any) => {
  store.commit("auth/setInitToken");
  store.state.isBalanceUpdate = true;

  try {
    const loginToken = {
      id,
      email,
      name,
      timestamp: Date.now(),
      social,
      os: platform.value,
    };

    store.commit("auth/setUserEmail", {
      userEmail: email,
    });
    store.commit("auth/setUserAccount", {
      userAccount: id,
    });

    const json = JSON.stringify(loginToken);

    const encode = openSSLCrypto.encode(json);

    store.dispatch("auth/socialLogin", {
      token: encode,
      fcmToken,
    });
  } catch (error) {
    console.error("Login failed", error);
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[14] || (_cache[14] = _createStaticVNode("<div class=\"h-56\"></div><div class=\"w-full flex\"><div class=\"wp-30\"></div><div class=\"wp-40 flex justify-center items-center\"><img src=\"" + _imports_0 + "\" alt=\"ZEROQUEST\"></div><div class=\"wp-20\"></div></div><div class=\"h-20\"></div>", 3)),
      _createElementVNode("div", _hoisted_2, [
        (platform.value === 'iOS')
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("div", null, [
                _createElementVNode("a", { onClick: loginWithKakao }, _cache[4] || (_cache[4] = [
                  _createElementVNode("img", {
                    class: "mb-4",
                    src: _imports_1,
                    alt: "카카오 로그인 버튼"
                  }, null, -1)
                ]))
              ]),
              _createElementVNode("div", null, [
                _createElementVNode("a", { onClick: loginWithApple }, _cache[5] || (_cache[5] = [
                  _createElementVNode("img", {
                    class: "mb-4",
                    src: _imports_2,
                    alt: "애플 로그인 버튼"
                  }, null, -1)
                ]))
              ]),
              _createElementVNode("div", null, [
                _createElementVNode("a", {
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (loginWithDaegu()))
                }, _cache[6] || (_cache[6] = [
                  _createElementVNode("img", {
                    class: "mb-10",
                    src: _imports_3,
                    alt: "다대구 로그인 버튼"
                  }, null, -1)
                ]))
              ])
            ]))
          : (platform.value === 'Mac OS X')
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("div", null, [
                  _createElementVNode("a", { onClick: loginWithKakao }, _cache[7] || (_cache[7] = [
                    _createElementVNode("img", {
                      class: "mb-4",
                      src: _imports_1,
                      alt: "카카오 로그인 버튼"
                    }, null, -1)
                  ]))
                ]),
                _createElementVNode("div", null, [
                  _createElementVNode("a", { onClick: loginWithApple }, _cache[8] || (_cache[8] = [
                    _createElementVNode("img", {
                      class: "mb-4",
                      src: _imports_2,
                      alt: "애플 로그인 버튼"
                    }, null, -1)
                  ]))
                ]),
                _createElementVNode("div", null, [
                  _createElementVNode("a", {
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (loginWithDaegu()))
                  }, _cache[9] || (_cache[9] = [
                    _createElementVNode("img", {
                      class: "mb-10",
                      src: _imports_3,
                      alt: "다대구 로그인 버튼"
                    }, null, -1)
                  ]))
                ])
              ]))
            : (platform.value === 'Android')
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createElementVNode("div", null, [
                    _createElementVNode("a", { onClick: loginWithKakao }, _cache[10] || (_cache[10] = [
                      _createElementVNode("img", {
                        class: "mb-4",
                        src: _imports_1,
                        alt: "카카오 로그인 버튼"
                      }, null, -1)
                    ]))
                  ]),
                  _createElementVNode("div", null, [
                    _createElementVNode("a", {
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (loginWithDaegu()))
                    }, _cache[11] || (_cache[11] = [
                      _createElementVNode("img", {
                        class: "mb-10",
                        src: _imports_3,
                        alt: "다대구 로그인 버튼"
                      }, null, -1)
                    ]))
                  ])
                ]))
              : (platform.value === 'Windows NT')
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createElementVNode("div", null, [
                      _createElementVNode("a", { onClick: loginWithKakao }, _cache[12] || (_cache[12] = [
                        _createElementVNode("img", {
                          class: "mb-4",
                          src: _imports_1,
                          alt: "카카오 로그인 버튼"
                        }, null, -1)
                      ]))
                    ]),
                    _createElementVNode("div", null, [
                      _createElementVNode("a", {
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (loginWithDaegu()))
                      }, _cache[13] || (_cache[13] = [
                        _createElementVNode("img", {
                          class: "mb-10",
                          src: _imports_3,
                          alt: "다대구 로그인 버튼"
                        }, null, -1)
                      ]))
                    ])
                  ]))
                : _createCommentVNode("", true)
      ])
    ]),
    _cache[15] || (_cache[15] = _createStaticVNode("<div class=\"break-words text-black media-Bottom\"><div class=\"font-semibold text-2xl\">제로퀘스트와 함께 지구를 살려요!🌍🎮</div><br><div class=\"font-medium text-lg\"> 탄소 발자국을 줄이는 행동, 그게 바로 우리의 게임 미션!<br> 성공할 때마다 ESG 포인트도 적립! <br> 포인트는 My point에서 카카오페이로 출금하면 재태크도 됩니다.<br> 계단 오르기부터 자전거 타기까지!<br> 제로퀘스트와 함께하면, 지구를 살리면서 주머니도 가득!<br> 함께 지구를 더 즐겁게, 건강하게 만들어가요! </div><br><div class=\"pt-4 font-semibold text-xl\"> 지금 바로 시작! <br> 제로퀘스트와 지구의 히어로가 되어보세요!🌟🚀<br> 쉿! 소문내기까지 하면 추가 보너스도 빵빵합니다. </div></div>", 1))
  ], 64))
}
}

})