import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { nextTick } from "vue";
import LoginView from "../views/LoginView.vue";
import store from "@/store";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Tutorial",
    component: () => import(/* webpackChunkName: "Tutorial" */ "../views/Tutorial.vue"),
  },
  {
    path: "/tutorialScroll",
    name: "TutorialScroll",
    component: () => import(/* webpackChunkName: "TutorialScroll" */ "../views/TutorialScroll.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/home",
    name: "home",
    component: () => import(/* webpackChunkName: "home" */ "../views/ZeroQuestView.vue"),
  },
  {
    path: "/home/referral",
    name: "referral",
    component: () => import(/* webpackChunkName: "home" */ "../views/ReferralView.vue"),
  },
  {
    path: "/zeronft",
    name: "zeronft",
    component: () => import(/* webpackChunkName: "zeronft" */ "../views/ZeroNftView.vue"),
  },
  {
    path: "/zeronftbuy/:idx",
    name: "zeronftbuy",
    component: () => import(/* webpackChunkName: "zeronftbuy" */ "../views/ZeroNftBuy.vue"),
  },
  {
    path: "/onft",
    name: "onft",
    component: () => import(/* webpackChunkName: "onft" */ "../views/OpenNftView.vue"),
  },
  {
    path: "/onft-detail/:idx/:tokenId",
    name: "onft-detail",
    component: () => import(/* webpackChunkName: "onft-detail" */ "../views/OpenNftDetailView.vue"),
  },
  {
    path: "/mywallet",
    name: "mywallet",
    component: () => import(/* webpackChunkName: "mywallet" */ "../views/MyWalletView.vue"),
  },
  {
    path: "/market",
    name: "market",
    component: () => import(/* webpackChunkName: "market" */ "../views/OpenMarketView.vue"),
  },
  {
    path: "/market/:id", // 'id'는 URL 파라미터입니다. 이 파라미터는 제품의 id를 가리킵니다.
    name: "OpenMarketViewDetail",
    component: () => import(/* webpackChunkName: "market" */ "../views/OpenMarketViewDetail.vue"),
    props: true,
  },
  {
    path: "/marketDelivery/:productCnt",
    name: "marketDelivery",
    component: () => import(/* webpackChunkName: "marketDelivery" */ "../views/Delivery.vue"),
  },
  {
    path: "/terms",
    name: "terms",
    component: () => import(/* webpackChunkName: "terms" */ "../views/TermsView.vue"),
  },
  {
    path: "/introduction",
    name: "introduction",
    component: () => import(/* webpackChunkName: "introduction" */ "../views/introduction.vue"),
  },
  {
    path: "/challenge",
    name: "challenge",
    component: () => import(/* webpackChunkName: "challenge" */ "../views/Challenge.vue"),
  },
  // {
  // 	path: '/WmuVoteDetail/:cardIdx',
  // 	name: 'WmuVoteDetail',
  // 	component: () =>
  // 		import(/* webpackChunkName: "terms" */ '../views/WmuVoteDetail.vue'),
  // },
  {
    path: "/Withdraw",
    name: "Withdraw",
    component: () => import(/* webpackChunkName: "Withdraw" */ "../views/Withdraw.vue"),
  },
  {
    path: "/TermsNotion",
    name: "TermsNotion",
    component: () => import(/* webpackChunkName: "TermsNotion" */ "../views/TermsNotion.vue"),
  },
  {
    path: "/NoticeEvent",
    name: "NoticeEvent",
    component: () => import(/* webpackChunkName: "TermsNotion" */ "../views/NoticeEvent.vue"),
  },
  {
    path: "/PreEntryApplication/:nftId/:mode",
    name: "PreEntryApplication",
    component: () =>
      import(
        /* webpackChunkName: "PreEntryApplication" */ "../views/carbonReductionTicket/PreEntryApplication.vue"
      ),
  },
  {
    path: "/ApplicationCompleted/:conferenceId",
    name: "ApplicationCompleted",
    component: () =>
      import(
        /* webpackChunkName: "PreEntryApplication" */ "../views/carbonReductionTicket/ApplicationCompleted.vue"
      ),
  },
  {
    path: "/ApplicationIncomplete/:conferenceId",
    name: "ApplicationIncomplete",
    component: () =>
      import(
        /* webpackChunkName: "PreEntryApplication" */ "../views/carbonReductionTicket/ApplicationIncomplete.vue"
      ),
  },
  {
    path: "/CompanyInfo/:conferenceId/:companyId",
    name: "CompanyInfo",
    component: () =>
      import(
        /* webpackChunkName: "PreEntryApplication" */ "../views/carbonReductionTicket/CompanyInfo.vue"
      ),
  },
  {
    path: "/ContactManagerInfoView/:conferenceId/:companyId",
    name: "ContactManagerInfoView",
    component: () =>
      import(
        /* webpackChunkName: "PreEntryApplication" */ "../views/carbonReductionTicket/ContactManagerInfoView.vue"
      ),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to: Nullable, from: Nullable, next: Nullable) => {
  store.state.isRouterLoading = true;

  const currentDate = new Date().getTime() / 1000;
  const expireAccessToken = store.getters["auth/getExpireAccessToken"];
  const expireRefreshToken = store.getters["auth/getExpireRefreshToken"];
  const isTutorialSkipped = store.getters["auth/getIsTutorialSkipped"];

  if (to.name === "Tutorial" && isTutorialSkipped === false) {
    return next();
  } else if (to.name === "Tutorial" && isTutorialSkipped === true) {
    return next({ name: "login" });
  }

  if (to.name === "login" && currentDate <= expireAccessToken) {
    if (currentDate <= expireRefreshToken) {
      return next("/" + process.env.VUE_APP_FIRST_URL);
    }
  } else if (
    to.name !== "login" &&
    to.name !== "terms" &&
    currentDate <= expireAccessToken &&
    store.getters["auth/getTerms"] != "" &&
    store.getters["auth/getTerms"] == 0
  ) {
    return next({ name: "terms" });
  }

  if (to.query.locale) {
    store.commit("system/SET_LOCALE", { lang: to.query.locale });
  }

  next();
});

router.afterEach(async (to: Nullable, from: Nullable, next: Nullable) => {
  store.state.isRouterLoading = false;

  nextTick(() => {
    const appWrap = document.getElementById("appMain");
    if (appWrap) {
      appWrap.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  });
});

export default router;
