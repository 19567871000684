import http from "@/api/http";

const updateRefreshToken = () => http.post("/auth/refresh");

const socialLogin = (token: string, fcmToken: any) => {
  return http.post("/auth/social", {
    token,
    fcmToken,
  });
};

export const prepareDaeguLoginApi = () => http.get("/auth/prepareDaeguLogin");

export const daeguLoginApi = (sessionKey: string, loginContext: object) => {
  return http.post("/auth/daeguLogin", {
    sessionKey,
    loginContext,
  });
};

export const getIsDidApi = () => http.get("/auth/isDid");

export const sendFcmTokenApi = (payload: any) => http.post("/api/fcmToken", payload);

export const handleSendVerificationCodeApi = (payload: any) =>
  http.post("/auth/phoneCode/send", payload);

export const checkVerificationCodeApi = (payload: any) =>
  http.post("/auth/phoneCode/verify", payload);

export const fetchInputFieldSettingApi = (payload: any) =>
  http.get(`api/user/user-info-fields/${payload}`);

export const buyTicketNftApi = (obj: any) => http.post("/api/nft/buyNft", obj);

export const fetchUserInfoApi = (payload: any) => http.get(`/api/ticket/userProfile/${payload}`);

export const editUserInfoApi = (nftId: any, formData: any) =>
  http.patch(`/api/ticket/userProfile/${nftId}`, formData);

export const fetchAppStatusApi = (conferenceId: any) =>
  http.get(`/api/ticket/regiStatus/${conferenceId}`);

export const updateEntryStateApi = (conferenceId: any) => {
  return http.patch(`/api/ticket/entryStatus/${conferenceId}`);
};

export const fetchCompanyInfoApi = (conferenceId: any, companyId: any) =>
  http.get(`/api/ticket/companyProfile/${conferenceId}/${companyId}`);

export const createReqMeetingApi = (conferenceId: any, companyId: any) =>
  http.post(`/api/ticket/companyUserProfile/${conferenceId}/${companyId}`);
export const fetchConferenceBannerApi = (conferenceId: any) =>
  http.get(`/api/ticket/conferenceBanner/${conferenceId}`);
export const fetchPamphletApi = (conferenceId: any, companyId: any) =>
  http.get(`/api/ticket/companyPamphlet/${conferenceId}/${companyId}`);
export const fetchPamphletDownApi = (filename: string) =>
  http.get(`/api/filedown/${filename}`, { responseType: "blob" });

export default {
  updateRefreshToken,
  socialLogin,
};
