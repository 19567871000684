<template>
  <div class="h-10"></div>
  <div class="h-10"></div>
  <div class="h-10"></div>
  <div class="fixed-bottom">
    <a :class="{ oPage: $route.name === 'home' }" @click="movePage('/home')">Home</a>
    <a :class="{ oPage: $route.name === 'zeronft' }" @click="movePage('/zeronft')">ZeroNFT</a>
    <a
      :class="{
        oPage: $route.name === 'onft' || $route.name === 'onft-detail',
      }"
      @click="movePage('/onft')"
      >PlayNFT</a
    >
    <a :class="{ oPage: $route.name === 'mywallet' }" @click="movePage('/mywallet')"
      >My<br />Point</a
    >
    <a :class="{ oPage: $route.name === 'market' }" @click="movePage('/market')">Eco<br />Market</a>
    <a
      :class="{
        oPage: $route.name === 'challenge',
      }"
      @click="movePage('/challenge')"
      >Challenge</a
    >
  </div>

  <Modal :visible="isPopup" title="서비스 준비중" @hide="closeModal" />
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();

const isPopup = ref(false);

const closeModal = () => {
  isPopup.value = false;
};

const movePage = (page: string) => {
  router.push(page);
};

// const movePage = (page: string) => {
// 	if (page === '/Challenge') {
// 		vuexStore.state.popupType = 'message';
// 		isPopup.value = true;
// 	} else {
// 		router.push(page);
// 	}
// };
</script>

<style scoped lang="scss"></style>
